import React, { useEffect, useLayoutEffect, useState } from "react";
import "../styles/EmpAddModal.scss";
import AppInput from "../../../layout/AppInput/AppInput";
import AppButton from "../../../layout/AppButton/AppButton";
import EventEmitter from "../../../../utils/EventEmitter";
import AppPhoneInput from "../../../layout/AppPhoneInput/AppPhoneInput";
import { useDispatch, useSelector } from "react-redux";
import { addError } from "../../../../store/reducers/alerts";
import AppUploader from "../../../layout/AppUploader/AppUploader";
import AppSelect from "../../../layout/AppSelect/AppSelect";
import { getDistrictListAsync } from "../../../../store/reducers/common/catalog";
import AppCheckbox from "../../../layout/AppCheckbox/AppCheckbox";

const DispatcherAddModal = ({ options, closeModal }) => {
  const dispatch = useDispatch();
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);
  const [modalTitle] = useState(setModalTitleData());
  const [actionButtonText] = useState(setActionButtonText());
  const [filePreviewData, setFilePreviewData] = useState("");
  const { districtList } = useSelector((store) => store.commonCatalog);
  const [districtListData, setDistrictListData] = useState(districtList);
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin'));

  const [emp, setEmp] = useState({
    username: options?.option?.username,
    email: options?.option?.email,
    fio: options?.option?.fio,
    id: options?.option?.id,
    idx: options?.option?.idx,
    phone: options?.option?.phone,
    photo: options?.option?.photo,
    district: options?.option?.district,
    active: true,
    meta: "existed",
  });

  const [isFioVal, setFioVal] = useState(false);
  const [isPhoneVal, setPhoneVal] = useState(false);
  const [isEmailVal, setEmailVal] = useState(false);
  const [isUsernameVal, setUsernameVal] = useState(false);
  // const [isDistrictVal, setDistrictVal] = useState(false);

  function setModalTitleData() {
    if (options?.mode === "edit") return "Редактировать диспетчера";
    else return "Добавить диспетчера";
  }

  function searchDis(id) {
    if (id) return districtListData.find((el) => el.id === id);
  }

  function setActionButtonText() {
    if (options?.mode === "edit") return "Редактировать диспетчера";
    else return "Добавить диспетчера";
  }

  function modalAction() {
    if (options?.mode === "edit") validate(editDispatcher, emp);
    else validate(addNewDispatcher, emp);
  }

  function setPhoto(eventData) {
    setEmp(eventData);
  }

  function setFilePreview(eventData) {
    setFilePreviewData(eventData);
  }

  function validate(func, data) {
    const errors = [];
    if (!data?.username?.length) errors.push("Не указан логин диспетчера");
    if (!data?.fio?.length) errors.push("Не указано имя диспетчера");
    if (!data?.email?.length) errors.push("Не указан email диспетчера");
    if (!data?.phone?.length) errors.push("Не указан телефон диспетчера");
    // if (!data?.photo) errors.push("Не добавлено фото диспетчера");
    if (!data?.district) errors.push("Не указан район");
    if (!errors?.length) {
      func();
    }
    if (errors?.length) {
      for (const error of errors) dispatch(addError(error));
    }
  }

  function editDispatcher() {
    EventEmitter.emit("editDispatcher", {
      ...emp,
      filePreview: filePreviewData,
    });
  }

  function addNewDispatcher() {
    EventEmitter.emit("addNewDispatcher", {
      ...emp,
      filePreview: filePreviewData,
    });
  }

  const checkValidFio = (isValid) => {
    setFioVal(isValid);
  };

  const checkValidPhone = (isValid) => {
    setPhoneVal(isValid);
  };

  const checkValidEmail = (isValid) => {
    setEmailVal(isValid);
  };

  const checkValidUsername = (isValid) => {
    setUsernameVal(isValid);
  };


  const isDisabledActionButton = () => {
    return !isFioVal || !isPhoneVal || !isEmailVal || !isUsernameVal || !emp?.district;
  };

  useEffect(() => {
    dispatch(getDistrictListAsync({ page: 1, page_size: 150000 }));
    if (dispatcherProfile?.is_admin) {
      setIsAdmin(dispatcherProfile?.is_admin);
    }
  }, []);

  useEffect(() => {
    setDistrictListData(districtList);
  }, [districtList]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (typeof options?.option === "object") {
        setEmp({
          ...options?.option,
        });
      }
    }, 0);
  }, [options?.option]);

  useEffect(() => {
    setTimeout(() => {
      setEmp({
        username: emp.username,
        email: emp.email,
        fio: emp.fio,
        id: emp.id,
        idx: emp.idx,
        phone: emp.phone,
        photo: emp.photo,
        district: emp.district,
        meta: emp.meta,
        active: true,
      });
    }, 0);
  }, [
    emp.email,
    emp.fio,
    emp.id,
    emp.idx,
    emp.meta,
    emp.phone,
    emp.photo,
    emp.district,
    emp.username,
  ]);

  return (
    <>
      <h1 className="add-emp-modal-name">{modalTitle}</h1>
      <div className="add-emp-wrapper">
        <AppUploader
          data={emp}
          setterFunc={setPhoto}
          setFilePreviewFunc={setFilePreview}
        />
      </div>
      <div className="input-area">
        <AppInput
          className="modal-input"
          label="ФИО"
          value={emp?.fio}
          placeholder="Указать ФИО"
          validations={{ isEmpty: true }}
          checkValid={checkValidFio}
          onEnterActionFunc={() => modalAction()}
          onInput={(e) => setEmp({ ...emp, fio: e })}
          isReq={true}
        />
        <AppInput
          className="modal-input"
          label="Логин (латинскими буквами, цифрами, без пробелов)"
          value={emp?.username}
          placeholder="Введите логин"
          validations={{ isEmpty: true }}
          checkValid={checkValidUsername}
          onEnterActionFunc={() => modalAction()}
          onInput={(e) => setEmp({ ...emp, username: e })}
          isReq={true}
        />
        <AppInput
          className="modal-input"
          label="E-mail"
          value={emp?.email}
          validations={{ isEmpty: true, isEmail: true }}
          placeholder="Указать e-mail"
          checkValid={checkValidEmail}
          onEnterActionFunc={() => modalAction()}
          onInput={(e) => setEmp({ ...emp, email: e })}
          isReq={true}
        />
        <AppPhoneInput
          className="modal-input"
          label="Телефон"
          value={emp?.phone}
          checkValid={checkValidPhone}
          validations={{ isEmpty: true, minLength: 18 }}
          placeholder="+7 (___) ___-__-__"
          onEnterActionFunc={() => modalAction()}
          onInput={(e) => setEmp({ ...emp, phone: e })}
          isReq={true}
        />
        <AppSelect
          label="Район работы"
          optionsList={districtListData}
          validations={{ isEmpty: true }}
          selected={searchDis(emp?.district?.id)}
          onInput={(e) => {
            setEmp({ ...emp, district: e })
          }}
          mode="white"
          isReq={true}
        />
        {!!isAdmin && (
            <AppCheckbox label="Активный?" value={emp?.active} checked={emp?.active} onChange={() => setEmp({...emp, active: !emp?.active }) } />
        )}
        <AppButton onClick={modalAction} isDisabled={isDisabledActionButton()}>
          {actionButtonText}
        </AppButton>
      </div>
    </>
  );
};

export default DispatcherAddModal;
